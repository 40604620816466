
.prompt {
  position: relative;
  display: flex;
  margin-top: 6px;
  padding: 24px;
  border: 1px solid $gray-4;
  border-radius: 20px;
  width: 894px;
  background: $gray-2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  &::before {
    content: '';
    position: absolute;
    left: 180px;
    top: -7px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: $gray-2;
    border-top: 1px solid $gray-4;
    border-left: 1px solid $gray-4;
  }
  &__icon-wrapper {
    margin-top: 3px;
    margin-right: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
    svg path {
      fill: $main;
    }
  }
  &__text-wrapper {
    max-width: 774px;
  }
  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $gray-8;
    min-height: 15px;
    //&:nth-child(1) {
    //  margin-bottom: 15px;
    //}
  }
  &__close-icon-wrapper {
    @include FlexCenter;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}


.prompt__mobile {
  display: none;
  &-drawer {
    display: none;
  }
};

@media (max-width: $max-sm-media) {
  .prompt__mobile {
    display: block;
    &-drawer {
      display: block;
      .ant-drawer-body {
        padding: 0 !important;
      }
      .ant-drawer-content-wrapper {
        height: auto !important;
      }
      .ant-drawer-content {
        border-radius: 16px 16px 0 0 !important;
      }
    }
    &-content {
      padding: 16px 16px 40px 16px;
    }
    &-title {
      text-align: center;
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: $gray-9;
    }
    &-btn {
      margin-top: 24px;
      width: 100%;
      height: 40px;
    }
    &-text {
      margin: 0 16px;
      font-size: 14px;
      line-height: 16px;
      color: $gray-8;
      min-height: 15px;
      //&:nth-child(3) {
      //  margin: 15px 16px 0 16px;
      //}
    }
    &-icon-wrapper {
      @include FlexCenter;
      position: fixed;
      top: 16px;
      left: 16px;
      cursor: pointer;
      z-index: 4;
      svg {
        width: 24px;
        height: 24px;
      }
      svg path {
        fill: $main;
      }
    }
  }
}

