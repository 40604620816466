
.dashboard-diagram {
  height: auto;
  width: 588px;

  & .dashboard-card__content {
    display: flex;
    padding: 0 24px 24px 24px;
  }
  & .dashboard-card__title {
    padding-bottom: 12px;
  }

  &-selector {
    margin-left: 31px;
  }

  &-radio {
    margin-bottom: 36px;
  }
  &-assets {
    font-weight: 400;
    line-height: 22px;
    font-size: 14px;
    color: $gray-9;

    tr:not(:last-child) {
      td {
        padding-bottom: 16px;
      }
    }

    &__name {
      padding-left: 14px;
      padding-right: 20px;
      position: relative;

      &:after {
        right: 0;
        top: 7px;
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        border-right: 1px solid $gray-5;
      }
    }
    &__ratio {
      padding-left: 20px;
      padding-right: 16px;
      color: $gray-7;
      text-align: end;
    }
  }
}
.dashboard-diagram__variable-radius-pie {
  width: 278px;
  height: 278px;
}


@media screen and (max-width: $max-sm-media) {
  .dashboard-diagram {
    width: 100%;
    height: auto;
    &-selector {
      margin-left: 5px;
    }
    & .dashboard-card__content {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
    & .dashboard-card__title {
      padding: 16px;
    }

  }
  .dashboard-diagram__variable-radius-pie {
    margin-bottom: 24px;
    width: 100%;
    height: 100%;
  }
}