.settings-safety {
  counter-reset: myCounter;
  color: $gray-9;
  .settings__item {
    .item-title {
      &:before {
        margin-right: 5px;
        counter-increment: myCounter;
        content: counter(myCounter) ".";
      }
    }
  }
  .auth-item {
    padding-top: 0;
    display: none;
    &_content {
      margin-left: 24px;
      span {
        margin-right: 16px;
        font-size: 16px;
        line-height: 24px;
      }
      .auth-switcher {
        background: $main;
        opacity: 0.4;
        border-radius: 16px;
      }
      p {
        margin-top: 8px;
        width: 219px;
        font-size: 16px;
        line-height: 24px;
        color: $gray-8;
      }
    }
  }
  .email-item {
    padding-top: 0;
  }
  .item_btn {
    margin-left: 24px;
  }
  .item_text {
    margin-left: 24px;
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;

    &.danger {
      color: $error-2
    }
  }
  .item-link {
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $main;

    &.email {
      margin-left: 16px;
    }
  }
}
