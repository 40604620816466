.dashboard-card {
  position: relative;
  width: auto;
  margin-bottom: 48px;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &.empty {
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
  }
  &__title {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding: 24px;
  }
  &__content {
    width: auto;
  }
}

@media screen and (max-width: $sm-media) {
  .dashboard-card {
    margin-bottom: 40px;
    &__title {
      padding: 16px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}