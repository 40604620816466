.asset-edit-connect-modal {
  &__wrapper {
    padding: 24px 88px 32px 88px;
  }

  &__api-key {
    //margin-bottom: 16px;
  }

  &__save-btn {
    margin-top: 24px;
    width: 100%;
    height: 40px;

    & span {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 291px;
    background: $gray-3;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &-logo {
      margin-right: 10px;
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      color: $gray-9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_text {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $main;
    text-decoration: underline;
  }

  & .ant-modal-header {
    background: #f3f4f9;
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .ant-modal-title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .asset-edit-connect-modal {
    &__wrapper {
      padding: 24px 16px 24px 16px;
    }

    &__save-btn {
      margin-top: 24px;

      & span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .ant-input {
      padding: 8px 12px;
    }
  }
}
