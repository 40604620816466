@media screen and(max-width: $max-lg-media) {
  .create-document__step-one {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__add-btn-wrapper {
      display: block;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    &__add-btn {
      @include TransparentBtn;
      height: 40px;
    }
    &__text {
      max-width: 414px;
    }
  }
}

@media screen and(max-width: $max-md-media) {
  .create-document__step-one{
    margin-top: 0;
  }
}

@media screen and(max-width: $max-sm-media) {
  .create-document__step-one {
    &__header {
      flex-direction: column;
    }
    &__add-btn-wrapper {
      margin-top: 0;
      width: 100%;
    }
    &__add-btn {
      @include TransparentBtn;
      height: 40px;
      width: 100%;
      position: static;
      right: 0;
      top: 0;
    }
    &__text {
      margin: 16px 0 16px 0;
      font-size: 14px;
      line-height: 18px;
      max-width: 100%;
    }
    &__all-check {
      margin-bottom: 16px;
    }
    &__check-wrapper {
      flex-direction: column;
      margin: 0;
    }
    &__check-item-wrapper {
      width: 100%;
      padding: 0;
    }
    &__check {
      width: 100%;
    }
    &__line {
      margin: 8px 0 16px 0;
    }
    .check-content-wrapper {
      width: 75%;
    }
  }
}
