.header-wrapper {
  @include Transition;
  position: absolute;
  background: $gray-1;
  width: 100%;
  height: 80px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  z-index: $header-index;

  .header-mobile {
    display: none;
  }
  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    height: 100%;
    &-logo {
      margin-right: 116px;
    }

    &-logo_mobile {
      display: none;
    }

    &-navigation ul {
      display: flex;
      li {
        .item-link {
          margin-right: 40px;
          svg {
            display: none;
          }
        }
        .item-link--active {
          color: $main;
          font-weight: bold;
        }
      }
    }

    &-right {
      margin-left: auto;
      svg {
        display: none;
      }
    }

    &-btn {
      background: inherit;
      border: none;
      cursor: pointer;
    }
  }
}

.headerScrollTop {
  position: fixed;
  top: -80px;
}

.headerFixed {
  position: fixed;
  top: 0;
}

.headerStatic {
  position: static;
}

.headerBoxShadowOff {
  box-shadow: none;
}
