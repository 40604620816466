.main.dashboard-page {
  padding-top: 0;
}

.dashboard {
  margin-top: 48px;
  justify-content: space-between;

  &-desktop {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
  }

  &-mobile {
    display: flex;
    flex-direction: column;
  }

  &-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  & .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  &-left {
    width: 792px;
  }
  &-right {
    width: 384px;
  }

  &-onboarding-btn {
    padding: 5px 16px 5px 20px;
    position: absolute;
    cursor: pointer;
    border: none;
    top: 230px;
    right: 0;
    background: $pink;
    z-index: 1;
    border-radius: 40px 0 0 40px;
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $gray-1;
    @include Transition;
    span {
      margin-right: 12px;
    }
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
    &.show {
      z-index: 1000;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }
  }
}


@mixin DashboardCup {
  &__cap {
    @include Transition;
    position: absolute;
    opacity: 0;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 134, 226, 0.85);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    border: 2px solid $blue-3;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &__content {
      position: absolute;
      right: 24px;
      text-align: center;
      display: flex;
      align-items: end;
      svg {
        width: 24px;
        height: 24px;
        transform: rotate(180deg);
      }
      svg path {
        fill: $gray-1;
      }
    }
    &__text {
      margin-right: 16px;
      font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: $gray-1;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .dashboard {
    &-top {
      flex-direction: column;
    }
    & .row {
      flex-direction: column;
    }
  }
}