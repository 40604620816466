@media screen and(max-width: $max-md-media) {
  .transactions-list {
    &__skeleton {
      width: 100%;
      margin: 0;

      &__title > span {
        margin: 24px 0 24px 15px;
      }

      &__items > div > span {
        border-radius: 0 !important;
        width: 100% !important;
      }
    }
  }
}

@media screen and(max-width: $max-sm-media) {
  .transactions-list {
    &__empty {
      margin-top: 40px;
      &-title {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 22px;
      }
      &-btn {
        margin-bottom: 24px;
      }
      &-text {
        font-size: 14px;
        line-height: 22px;
      }
    }
    &__show-more {
      &__wrapper {
        width: auto;
        margin: 16px 11px 0 11px;
      }
      border-radius: 2px;
    }
    &__skeleton {
      &__title {
        display: flex;
        justify-content: center;
      }

      &__title > span {
        margin: 18px auto;
      }
    }
  }
}
