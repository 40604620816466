.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.transactions-header {
  @include Transition;
  background: $gray-2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
  &__content {
    padding: 15px;
  }
  .transactions-title__wrapper {
    @include Transition;
    display: flex;
    align-items: center;
    height: 40px;
    margin: 110px 0 20px 0;
    color: $gray-9;
    opacity: 1;
    h1 {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
    }
    span {
      @include FlexCenter;
      margin-left: 10px;
      width: 41px;
      height: 26px;
      background: $gray-4;
      border-radius: 40px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}


