.aml-single {
  &-wrapper {
    width: 100%;
  }
  &__main {
    position: relative;
    padding: 24px 24px 40px 24px;
    background: $gray-1;
    border-radius: 8px;
    border: 1px solid $gray-4;
  }
  &__header {
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      align-items: center;
    }
    &__icon {
      & > img {
        width: 40px;
        height: 40px;
      }
    }
    &__naming {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
    }
    &__name {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }
    &__source {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-7;
    }
    &__data {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__risk {
      margin-left: auto;
      margin-bottom: 4px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      &__icon {
        width: 16px;
        height: 16px;
      }
    }
    &__last-check {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray-7
    }
    &__bottom {
      margin-left: 57px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__risk-rising {
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $pink;
    }
    &__check-for-aml {
      width: 170px;
      height: 40px;
      margin-top: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $gray-9;
    }
    &__copy {
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }
  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }
  &__radius-pie {
    width: 256px;
    height: 256px;
  }
  &__sources {
    margin-top: 30px;
    margin-left: 60px;

    &__table {
      font-weight: 400;
      line-height: 22px;
      font-size: 14px;
      color: $gray-9;
    }

    &__title {
      margin-bottom: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: $gray-8;
    }

    tr:not(:last-child) {
      td {
        padding-bottom: 8px;
      }
    }

    &__name {
      padding-left: 14px;
      padding-right: 20px;
      position: relative;

      &:after {
        right: 0;
        top: 7px;
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        border-right: 1px solid $gray-5;
      }
    }
    &__ratio {
      padding-left: 20px;
      padding-right: 16px;
      color: $gray-8;
      text-align: end;
    }
  }
  &__history {
    margin-top: 40px;
    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: $gray-8;
      margin-bottom: 8px;
    }
    &__card {
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 8px;
      margin-bottom: 16px;

      &__icon-is-active {
        transform: rotate(180deg);
        &, path {
          fill: $main
        }
      }
      & .ant-collapse-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        svg {
          margin-left: auto;
        }
      }
      & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 20px 16px 16px 24px;
      }

      &__header {
        width: 100%;
        margin-right: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__datetime {
          display: flex;
          flex-direction: column;
        }
        &__date {
          font-size: 16px;
          line-height: 22px;
        }
        &__time {
          font-size: 14px;
          line-height: 22px;
          color: $gray-7;
        }
        &__naming {
          margin-left: 56px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        &__name {
          margin-right: auto;
          font-size: 16px;
          line-height: 22px;
        }
        &__subnmae {
          font-size: 16px;
          line-height: 22px;
          color: $gray-7;
        }
        &__risk {
          margin-left: auto;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          &__icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .aml-single {
    &__main {
      background: transparent;
      border: none;
    }
    &__header {
      &__data {
        margin-left: 0;
      }
      &__risk {
        margin-left: 0;
        &.wallet-history {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &__last-check {
        font-size: 12px;
        line-height: 20px;
        color: $gray-7
      }
      &__bottom {
        margin-left: 0;
      }
      &__risk-rising {
        font-size: 12px;
        line-height: 20px;
        background: #E8E9F0;
        border-radius: 40px;
        padding: 0 7px;
      }
      &__check-for-aml {
        position: absolute;
        top: 10px;
        right: 0;
      }
      &__copy {
        right: 0;
        bottom: -10px;
      }
    }
    &__content {
      margin-top: 16px;
      flex-direction: column;
    }
    &__radius-pie {
      margin: 0 auto;
    }
    &__sources {
      margin-top: 0;
      margin-left: 0;
    }
    &__history {
      margin-top: 52px;
      &__card {
        &__header {
          cursor: pointer;
          padding: 20px 14px 16px 16px;
          &__date {
            font-size: 14px;
            line-height: 22px;
          }
          &__time {
            font-size: 12px;
            line-height: 17px;
            color: $gray-7;
          }
          &__naming {
            margin-left: 16px;
          }
          &__name {
            font-size: 14px;
          }
          &__subnmae {
            font-size: 14px;
          }
          &__risk {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}