.dashboard-taxes {
  @include DashboardCup;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 242px;
  padding: 32px 0 26px 0;
  margin-bottom: 48px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 50px;
  overflow: hidden;
  &__header {
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__title {
      margin-right: 6px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  &__cap {
    border-radius: 50px;
    &__content {
      top: 109px;
    }
  }
  & table {
    width: 100%;

    & tbody {
      width: 100%;
    }
  }
  &-item:not(:first-child) {
    border-top: 1px solid $gray-3;
  }
  &-item {
    width: 100%;
    position: relative;
    display: flex;
    padding: 14px 14px 14px 40px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: $gray-light;
    }
    &:hover &__arrow-wrapper {
      opacity: 1;
    }
    &.disabled-event {
      pointer-events: none;
    }
    &__year {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: $main;
    }
    &__border {
      margin-left: 24px;
      margin-right: 36px;
      &:after {
        content: "";
        position: absolute;
        text-align: center;
        top: 25px;
        width: 1px;
        height: 24px;
        border-right: 1px solid $gray-5;
      }
    }
    &__data {
      padding: 0;
      width: 101px;
      &_title {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      &_value {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        &_tax {
          font-weight: 500;
          font-size: 14px;

          &.not-formed {
            font-weight: 400;
            color: $gray-7;
          }
        }
      }
    }
    &__arrow-wrapper {
      @include Transition;
      opacity: 0;
      position: absolute;
      right: 16px;
      margin-left: auto;
      transform: rotate(180deg);
      svg {
        width: 12px;
        height: 20px;
      }
      svg path {
        fill: $gray-6;
      }
    }
  }
}


@media screen and (max-width: $max-sm-media) {
  .dashboard-taxes {
    width: 100%;
    height: auto;
    padding: 20px 24px 20px 16px;
    border-radius: 4px;
    margin-bottom: 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__header {
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-bottom: 0;
      align-items: start;

      &__title {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
      }
    }
    &__cap {
      border-radius: 4px;
      left: 0;
      &__content {
        top: 32px;
      }
    }
    &__items {
      display: flex;
      flex-direction: row-reverse;
    }
    &-item:not(:first-child) {
      border-top: none;
      padding-right: 8px;
    }
    &-item {
      width: auto;
      min-width: 77px;
      align-items: flex-start;
      flex-direction: column;
      border-top: none;
      padding: 0;

      &:first-child {
        border-left: 1px solid $gray-5;
        padding: 0 0 0 25px;
      }
      &:hover {
        background: none;
      }
      &__data {
        width: auto;
        min-width: 70px;
        white-space: nowrap;
      }
      &__year {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 6px;
      }
      &__data_value_tax {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}