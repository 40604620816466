.transactions-edit__currency {
  height: 40px;
  margin-left: 16px;
  min-width: 86px;
  border-radius: 4px;
  background: $gray-3;
  cursor: pointer;
  &-menu {
    &.overflow {
      max-height: 126px;
      overflow-y: scroll;
    }
  }
  &-item-input {
    display: none;
    width: 86px;
    height: 40px;
    background: $gray-3;
    padding: 9px 10px 9px 8px;
    border: 1px solid $main;
    box-sizing: border-box;
    border-radius: 4px;
    &.focus {
      display: inline-block !important;
    }
    &:focus {
      outline: none;
    }
  }
  &-item-wrapper {
    display: flex;
    padding: 8px;
    align-items: center;
    svg, img {
      width: 24px;
      height: 24px;
    }
    p {
      margin-left: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $gray-9;
      text-transform: uppercase;
    }
    &.click {
      display: none;
    }
  }
  &-item-skeleton {
    padding: 4px
  }
}


.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  padding: 0;
}