.transactions-filter {
  display: flex;
  flex-wrap: wrap;
  .select {
    margin-right: 24px;
    width: 180px;
  }
  .date-picker {
    margin-right: 24px;
    width: 215px;
  }
  .select-assets {
    width: 282px;
  }
  &__btn {
    background: inherit;
    color: $main;
    border: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
  }
}
