.document-create-modal {
  & .default-modal__content {
    padding: 24px 80px;
  }
  & .default-modal__form {
    padding: 0;
  }
}
.document-create-modal-form {
  width: 100%;
  &__item {
    @include Transition;
    width: 100%;
    padding: 8px;

    &:not(:first-child) {
      margin-top: 8px;
    }
    &.ant-radio-wrapper-checked {
      background: $gray-light;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    &__title {
      margin-bottom: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $gray-9;
    }
    &__text {
      width: 332px;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
    }
  }
  &__btn {
    margin: 32px 0 24px 0;
    width: 100%;
    height: 40px;
  }
}