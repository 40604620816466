.aml {
  @include Scroll;
  display: flex;
  &-page {
    display: flex;
    align-items: center;
  }
  &-title_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }
  &-title {
    margin-right: 10px;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
  }
  &-count {
    @include FlexCenter;
    padding: 0 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 50px;
    background: $gray-4;
  }
  &-content {
    display: flex;
    padding-left: 24px;
    justify-content: center;
    max-width: calc(100% - 315px);
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: $gray-9;
  }
  &-sidebar {
    &__additional {
      margin-top: 60px;
    }
    &__manual-checks, &__archive {
      display: flex;
      align-items: center;
      height: 60px;
      background: $gray-1;
      border: 1px solid $gray-4;
      border-radius: 4px;
      & .title {
        margin-left: 16px;
      }
      & .count {
        z-index: 0;
        position: relative;
        margin-left: auto;
        float: right;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        &::before {
          z-index: -1;
          position: absolute;
          content: "";
          width: 16px;
          height: 16px;
          background: $gray-4;
          border-radius: 50%;
          top: 3px;
          left: -5px;
        }
      }
    }
    &__manual-checks {
      padding-right: 16px;
      padding-left: 22px;
      svg {
        width: 24px;
        height: 24px;
      }
      & .title {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__archive {
      margin-top: 16px;
      padding-right: 20px;
      padding-left: 22px;
      svg {
        width: 20px;
        height: 20px;
      }
      & .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-8;
      }
    }
  }
  &-list_wrapper {
    display: flex;
    width: 384px;
    max-height: 100%;
  }
  &-list_line {
    width: 1px;
    background: $gray-4;
  }
  &-list {
    position: relative;
    height: 442px;
    width: 100%;
    overflow-y: scroll;
    margin-right: 2px;
    min-height: 200px;
    .empty-text {
      padding: 0 20px;
      margin-top: 100px;
      text-align: center;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 10px;
    width: 360px;
    height: 60px;
    background: $gray-3;
    border-radius: 4px;
    cursor: pointer;
    transition: border 0.3s ease;
    &:last-child {
      margin-bottom: 0;
    }
    &-logo {
      margin-right: 8px;
      padding: 4px;
      width: 40px;
      height: 40px;
    }
    &__naming {
      display: flex;
      flex-direction: column;
    }
    &__name {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
    &__subname {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $gray-7;
    }
    &__data {
      margin-left: auto;
      display: flex;
      flex-direction: column;
    }
    &__risk {
      margin-left: auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
    &__transactions {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: $gray-7;
    }
    &.active {
      border: 1px solid $main;
    }
  }
  &-btn {
    margin-top: 40px;
    height: 40px;
    width: 360px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: $max-lg-media) {
  .aml {
    &-btn {
      width: 100%;
    }
    .item {
      width: 300px;
    }
    &-item {
      width: 287px;
    }
    &-list {
      &_wrapper {
        width: 300px;
      }
      width: 300px;
    }
    &-content {
      padding-left: 24px;
      max-width: calc(100% - 180px);
    }
  }
}
@media screen and (max-width: $max-sm-media) {
  .aml {
    flex-direction: column;
    height: 100%;
    &-title_wrapper {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $gray-8;
    }
    &-count {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
    &-list {
      max-height: none;
      height: auto;
      width: 100%;
      margin-right: 0;
      overflow-y: auto;
      &_wrapper {
        width: 100%;
        height: auto;
      }
    }
    &-btn {
      margin-top: 24px;
    }
    &-item {
      width: 100%;
      margin-bottom: 4px;
      background-color: $gray-1;
      border: 1px solid #E8E9F0;
      &-logo {
        margin-right: 10px;
      }
    }
    &-content {
      padding: 0 1px;
      max-width: 100%;
    }
    &__additional {
      margin-top: 40px;
    }
  }
}
@media (min-width: $md-media) and (max-width: $xxl-media) {
  .aml {
    &-list_line {
      width: 1px;
      background: $gray-4;
    }

  }
}
