.dashboard-symbols {
  width: 100%;
  min-height: 74px;
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  &__setting {
    margin: auto 16px auto 0;
    svg {
      cursor: pointer;
    }
  }
  &-set-sequence-modal {
    & .default-modal__content {
      padding: 16px 0 32px 0;
    }
  }
  &-set-sequence-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .save-btn {
      &-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 16px;
      }
      margin-top: 24px;
      width: 460px;
      height: 40px;
    }
  }
  &-set-sequence-items {
    width: 100%;
  }
  &-set-sequence-item {
    position: relative;
    display: flex;
    justify-content: center;
    height: 72px;
    padding: 16px;
    @include Transition;
    &:hover {
      background: $gray-2;
    }
    &__burger-icon {
      position: absolute;
      top: 30px;
      right: 23px;
      cursor: move;
    }
  }
  &-set-sequence-item-dropdown {
    height: 40px;
    width: 460px;
    min-width: 86px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: $gray-1;
    &-menu {
      &.overflow {
        max-height: 213px;
        overflow-y: scroll;
      }
      &.empty {
        background: $gray-1;
        height: 213px;
        width: 460px;
      }

      &-item-skeleton {
        padding: 4px
      }
      &-item-wrapper {
        padding: 8px 6px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid $gray-3;
        svg, img {
          width: 24px;
          height: 24px;
        }
        &-label {
          width: 100%;
          margin-left: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .label-title {
            margin-left: 6px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
          }
          .label-detail {
            width: 110px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .type {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: $gray-8;
              text-transform: capitalize;
            }
            .source {
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-transform: capitalize;
            }
            .arrow {
              display: none;
            }
          }
        }
      }
    }
    &-wrapper {
      border-radius: 4px;
      border: 1px solid $gray-4;
      padding: 8px 20px 8px 8px;
      width: 100%;
      display: flex;
      align-items: center;
      svg, img {
        width: 24px;
        height: 24px;
      }
      &-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label-title {
          margin-left: 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-transform: uppercase;
        }
        .label-detail {
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .type {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $gray-8;
            text-transform: capitalize;
          }
          .source {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-transform: capitalize;
          }
          .arrow {
            transform: rotate(-90deg);
            width: 12px;
            height: 12px;
            fill: $main;
          }
        }
      }
      &.click {
        display: none;
      }
    }
    &-input {
      display: none;
      width: 100%;
      height: 40px;
      padding: 9px 10px 9px 8px;
      border: 1px solid $main;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      &.focus {
        display: inline-block !important;
      }
      &:focus {
        outline: none;
      }
    }

  }
}

.dashboard-symbol {
  position: relative;
  height: 74px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  cursor: default;
  @include Transition;
  &:hover {
    -webkit-box-shadow: 0px 25px 50px 15px rgba(0,71,255,0.05);
    box-shadow: 0px 25px 50px 15px rgba(0,71,255,0.05);
    background: $gray-1;
  }
  &__empty {
    width: 180px;
  }
  &__icon {
    svg {
      width: 24px;
      height: 24px;
    }
    margin-right: 8px;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__symbol {
    display: flex;
    font-family: 'Roboto', serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-9;
  }
  &__source-icon {
    margin-left: 25px;
    width: 24px;
    position: relative;
    svg {
      position: absolute;
      top: -6px;
      width: 24px;
      height: 24px;
      path {
        fill: $gray-light;
      }
    }
    opacity: 1;
  }
  &__course {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__price {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray-9;
    margin-right: 4px;
    white-space: nowrap;
    &.positive {
      color: $green-3;
    }
    &.negative {
      color: $complementary
    }
  }
  &__change {
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $green-3;
    white-space: nowrap;
    &.negative {
      color: $complementary
    }
  }

  &__source {
    position: absolute;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    opacity: 0;
    top: 76px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $gray-6;
    @include Transition;
  }
  &:hover &__source {
    opacity: 1;
  }
}


@media screen and (max-width: $max-sm-media) {
  .dashboard-symbols {
    width: 100%;
    min-height: 32px;
    margin-top: 0;
    margin-bottom: 48px;
    padding: 6px 16px;
    justify-content: left;
    background: $gray-4;
    overflow: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .dashboard-symbols::-webkit-scrollbar {
    display: none;
  }
  .dashboard-symbol {
    &:not(:last-child) {
      margin-right: 16px;
    }
    &:hover {
      filter: none;
      background: none;
    }
    &__empty {
      width: 0;
    }
    width: auto;
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    &__symbol {
      margin-right: 5px;
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
    }
    &__content {
      flex-direction: row;
    }
    &__price {
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;
    }
  }
}
