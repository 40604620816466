@media screen and(max-width: $max-md-media) {
  .transactions-filter {
    row-gap: 24px;
    .select-assets {
      width: 248px;
    }
    .date-picker {
      margin-right: 16px;
      width: 160px;
    }
  }
}

@media screen and(max-width: $max-xs-media) {
  .transactions-filter {
    flex-direction: column;
    &--flex {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .select {
        margin-right: 0 !important;
        width: 148px !important;
      }
    }
    .select {
      margin-right: 0;
      width: 100%;
    }
    .date-picker {
      width: 100%;
    }
  }
}
