@media screen and(max-width: $max-lg-media) {
  .report-card {
    padding: 14px 20px 16px 24px;
    margin-bottom: 0;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid $gray-5;
    box-shadow: none;
    &:first-of-type {
      border-top: none;
    }
    &__date {
      font-size: 12px;
      line-height: 16px;
    }
    &__title {
      margin: 8px 0 4px 0;
      font-size: 16px;
      line-height: 24px;
    }
    &__status {
      font-size: 14px;
      line-height: 18px;
    }
    &__arrow-wrapper {
      width: 24px;
      height: 24px;
      svg {
        width: 12px;
        height: 20px;
      }
    }
  }
}

@media screen and(max-width: $max-lg-media) {

}
