.dashboard-your-accounts {
  display: flex;
  flex-direction: column;
  width: 384px;
  height: 242px;
  padding: 32px 48px 24px 48px;
  margin-bottom: 48px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 50px;
  &__header {
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &__title {
      margin-right: 6px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__plus {
      position: relative;
      line-height: 24px;
      cursor: pointer;
      margin-left: 8px;
      svg {
        position: absolute;
        top: 0;
        path {
          fill: $gray-9;
        }
      }
    }
  }
  &__content {
    height: 56px;
    display: flex;
    margin-bottom: 27px;
    justify-content: space-between;
    &__col {
      display: flex;
      flex-direction: column;
    }
  }
  &__type {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $gray-9;
    white-space: nowrap;
    &__count {
      margin-right: 4px;
      color: $gray-9;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__loading {
      width: 100%;
    }
    .ant-skeleton-button {
      height: 20px;
    }
  }
  &__sync {
    display: flex;
    justify-content: center;
    &__btn {
      height: 40px;
      min-width: 156px;
      color: $gray-9;
      background: $lettuce;
      border-radius: 40px;
      padding: 9px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      @include Transition;
      svg {
        margin-right: 8px;
        padding: 2px;
        width: 16px;
        height: 16px;
        path {
          fill: $gray-9;
        }
      }
      &:hover {
        color: $brown-9;
        background: $lettuce;
        opacity: .8;
      }
      &:focus {
        color: $brown-9;
        background: $lettuce;
      }
      .sync-loader {
        position: absolute;
        left: 23px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.error {
        color: $gray-1;
        background-color: $red-6;
        svg path {
          fill: $gray-1;
        }
        &:hover {
          color: $gray-1;
          background-color: $red-5;
          svg path {
            fill: $gray-1;
          }
        }
      }
      &.synchronizing {
        pointer-events: none;
        color: $gray-1;
        background-color: $gray-6;
        padding-left: 40px;
        svg path {
          fill: $gray-1;
        }
        &:hover {
          svg path {
            fill: rgba(0, 0, 0, 0.25);
          }
        }
        &:focus {
          color: $gray-1;
          background-color: $gray-6;
          svg path {
            fill: $gray-1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .dashboard-your-accounts {
    width: 100%;
    height: 80px;
    padding: 20px 24px 20px 16px;
    border-radius: 4px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    &__header {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-bottom: 0;
      align-items: flex-start;
      &__title {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        span {
          cursor: pointer;
          width: 20px;
          height: 20px;
          margin-left: 4px;
          svg {
            height: 14px;
            width: 14px;
          }
        }
        .sync-loader {
          position: absolute;
          top: 9px;
          right: -1px;
          transform: translate(-50%, -50%);
        }
      }
      &__status {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $gray-6;
      }
    }
    &__content {
      height: 40px;
      margin-bottom: 0;
      &__col:first-child {
        margin-right: 16px;
      }
    }
    &__type {
      font-size: 12px;
      line-height: 20px;
      color: $gray-9;
      &__count {
        color: $gray-9;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: $sub-sm-media-50) {
  .dashboard-your-accounts {
    &__header {
      &__status {
        width: 120px;
      }
    }
  }
}

@media screen and (max-width: $md-xs-media) {
  .dashboard-your-accounts {
    flex-direction: column;
    height: auto;
    &__content {
      margin-top: 16px;
    }
  }
}