.ant-modal {
  @include Scroll;
  border-radius: 15px !important;
  .ant-modal-close {
    position: absolute;
    top: 18px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    span {
      width: auto;
      height: auto;
    }
    svg {
      fill: rgba(0, 0, 0, 0.85);
    }
  }

  .ant-modal-content {
    margin: 0;
    border-radius: 15px;
    overflow: hidden;
  }

  .add-modal {
    height: 451px;
    overflow: hidden;
    border-radius: 15px;
    &_header {
      width: 100%;
      position: absolute;
      z-index: 2;
      border-radius: 15px 15px 0 0;
      padding: 24px 24px 0 24px;
      background: #f3f4f9;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    }

    &_title {
      text-align: center;
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 28px;
    }

    &_filter {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .filter-input_wrapper {
        width: 100%;

        input {
          width: 100%;
        }

        .search-input {
          border-radius: 2px 0 0 2px;

          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $gray-6;
          }

        }
        div {
          border-radius: 0 2px 2px 0;
        }
      }
      .add-modal-tabs {
        margin-top: 12px;
        .ant-tabs-nav {
          margin: 0;
        }
      }
      .ant-tabs-tab {
        padding: 0 0 3px 0;
      }
      .ant-tabs-tab-btn {
        padding: 0 16px;
        font-family: 'Roboto', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: $gray-8;
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $main;
        }
      }
    }

    &_list {
      top: 171px;
      position: absolute;
      //position: relative;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, 180px);
      grid-template-rows: repeat(3, 60px);
      grid-column-gap: 24px;
      grid-row-gap: 16px;
      //margin: 0 4px;
      height: 280px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 24px;
      .empty-text {
        position: absolute;
        top: 45%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 282px;
      }

      .item-wrapper {
        //width: 1fr;
        //max-width: 178px;
        .item {
          position: relative;
          display: flex;
          align-items: center;
          padding: 10px;
          height: 60px;
          background: $gray-3;
          border-radius: 4px;
          cursor: pointer;
          text-overflow: ellipsis;
          &.soon {
            cursor: auto;
          }

          &-logo {
            margin-right: 4px;
            padding: 4px;
            width: 40px;
            height: 40px;
          }

          &-title {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 24px;
            color: $gray-9;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.soon {
              color: $gray-7;
            }
          }
          &-soon {
            position: absolute;
            top: -5px;
            left: -5px;
          }
        }
      }
      .item-wrapper:last-child {
        padding-bottom: 16px;
      }
    }
    ._isLoadingAddModal {
      @include FlexCenter;
    }
    ._isScrollModal {
      padding-bottom: 10px;
    }
  }

  .connect-modal {
    min-height: 394px;

    &.EXCHANGE {
      min-height: 454px;
    }
    &.WALLET {
      min-height: 486px;
    }

    &_header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 16px 24px;
      background: #f3f4f9;
      border-radius: 15px 15px 0 0;

      .close-icon {
        position: absolute;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 12px;
        top: 18px;
        cursor: pointer;
      }
    }

    &_info {
      padding: 10px 16px 10px 10px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      max-width: 291px;
      background: $gray-3;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .info-logo {
        margin-right: 10px;
        width: 40px;
        height: 40px;

        img {
          width: 40px;
          height: 40px;
        }
      }
      .info-title {
        font-size: 16px;
        line-height: 24px;
        color: $gray-9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__skeleton {
        height: 60px;
        width: 130px;
      }
    }

    &_form {
      label {
        font-size: 14px;
        line-height: 18px;
      }

      &__blockchein {
        position: relative;

        &__delete {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          font-size: 14px;
          line-height: 18px;
          color: $red-6;
        }
        &__stock {
          margin-top: 8px;
        }
      }
      &__blockcheins {
        min-height: 172px;

        &__add-blockchein {
          display: flex;
          justify-content: right;
          span {
            margin-top: 16px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: right;
            color: $main;
          }
        }
      }
    }

    &_text {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $main;
      text-decoration: underline;
    }

    &_content {
      padding: 24px 0 32px 0;
      margin: 0 auto;
      max-width: 460px;
      &__skeleton {
        display: flex;
        flex-direction: column;
        .ant-space-item {
          width: 100%;
        }
        .ant-skeleton {
          width: 100%;
        }
        .ant-skeleton-button {
          margin-bottom: 16px;
          width: 100%;
          height: 60px;
        }
      }
    }

    &_text--bottom {
      display: flex;
      align-items: top;
      padding: 0 24px;
      font-size: 14px;
      line-height: 20px;
      color: $gray-7;

      span {
        margin-left: 18px;
      }

      svg {
        height: 24px;
        width: 24px;
        margin-top: 5px;
      }
    }
  }
}

.ant-modal-body {
  padding: 0 !important;
}

.default-modal {
  display: flex;
  flex-direction: column;
  max-width: 636px !important;
  width: 100%;
  &__content {
    padding: 24px 88px 32px 88px;
  }
  &__btn {
    width: 100%;
    height: 40px;
  }
  .ant-modal-close {
    display: none;
  }
}
